import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Layout } from '../../components/layout'

const TIST23 = () => {
  return (
    <Layout>
      <div className="mt-16">
        <section className="hero">
          <div className="hero-body">
            <div className="container mx-auto">
              <div className="flex justify-center">
                <div className="">
                  <h1 className="text-4xl font-bold text-center">
                    Semantic Collaborative Learning for Cross-Modal Moment Localization
                  </h1>
                  <div className="text-xl mt-4 flex justify-center space-x-1">
                    <div>
                      <a href="https://faculty.sdu.edu.cn/huyupeng1/zh_CN/index.htm" className="hover:underline">
                        Yupeng Hu
                      </a>
                      <sup>1</sup>,
                    </div>
                    <div>
                      <a href="https://khylon.wang" className="hover:underline">
                        Kun Wang
                      </a>
                      <sup>1</sup>,
                    </div>
                    <div>
                    <a href="https://mengliu1991.github.io/" className="hover:underline">
                      Meng Liu
                    </a>
                    <sup>2</sup>,
                    
                    </div>
                    <div>
                      <a href="https://faculty.sdu.edu.cn/tanghaoyu/zh_CN/index.htm" className="hover:underline">
                      Haoyu Tang
                    </a>
                    <sup>1</sup>, 
                    </div>
                    <div>
                      <a href="https://liqiangnie.github.io/" className="hover:underline">
                        Liqiang Nie
                      </a>
                      <sup>3</sup>
                    </div>
                  </div>

                  <div className="text-xl mt-4 flex justify-center space-x-2">
                    <div>
                      <sup>1</sup>Shandong University
                    </div>
                    <div>
                      <sup>2</sup>Shandong Jianzhu University
                    </div>
                    <div>
                      <sup>3</sup>Harbin Institute of Technology (Shenzhen)
                    </div>
                  </div> 
                  <div className="mt-6 text-xl">
                    <div className="flex justify-center space-x-4">
                      <a href="https://dl.acm.org/doi/full/10.1145/3620669" className="btn btn-dark">
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faFilePdf} />
                        </span>
                        <span>Paper</span>
                      </a>
                      {/* <a href="https://github.com/KhylonWong" className="btn btn-dark" onClick={(e) => e.preventDefault()}>
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faDownload} />
                        </span>
                        <span>Code</span>
                      </a> */}
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
        <div className="container max-w-4xl mx-auto mt-8">
          <StaticImage src="../../images/papers/tist23/framework.png" alt="Framework" />
        </div>
        <section className="section">
          <div className="mt-12 container mx-auto">
            <div className="flex justify-center">
              <div className="">
                <h2 className="text-3xl font-bold text-center">Abstract</h2>
                <div className="text-xl mt-4" style={{ textAlign: 'justify' }}>
                  <p>
                    Localizing a desired moment within an untrimmed video via a given natural language query, i.e.,
                    cross-modal moment localization, has attracted widespread research attention recently. However, it
                    is a challenging task because it requires not only accurately understanding intra-modal semantic
                    information, but also explicitly capturing inter-modal semantic correlations (consistency and
                    complementarity). Existing efforts mainly focus on intra-modal semantic understanding and
                    inter-modal semantic alignment, while ignoring necessary semantic supplement. Consequently, we
                    present a cross-modal semantic perception network for more effective intra-modal semantic
                    understanding and inter-modal semantic collaboration. Concretely, we design a dual-path
                    representation network for intra-modal semantic modeling. Meanwhile, we develop a semantic
                    collaborative network to achieve multi-granularity semantic alignment and hierarchical semantic
                    supplement. Thereby, effective moment localization can be achieved based on sufficient semantic
                    collaborative learning. Extensive comparison experiments demonstrate the promising performance of
                    our model compared with existing state-of-the-art competitors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section mt-12" id="BibTeX">
          <div className="container mx-auto text-left">
            <h2 className="text-3xl font-bold">BibTeX</h2>
            <div className="mt-4 container overflow-x-auto py-2 px-2" style={{ backgroundColor: '#f5f5f5' }}>
              <pre className="whitespace-pre text-left">
                <code className="block pl-4">
                  {`@article{hu2023semantic,
  title={Semantic collaborative learning for cross-modal moment localization},
  author={Hu, Yupeng and Wang, Kun and Liu, Meng and Tang, Haoyu and Nie, Liqiang},
  journal={ACM Transactions on Information Systems},
  volume={42},
  number={2},
  pages={1--26},
  year={2023},
  publisher={ACM New York, NY, USA}
}`}
                </code>
              </pre>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default TIST23
